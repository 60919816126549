//
// Polices
//

// https://fontsource.org/fonts/aleo
import '@fontsource/aleo/400.css';
import '@fontsource/aleo/700.css';

// https://fontsource.org/fonts/montserrat
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/700-italic.css';

//
// Application
//

import './components/scroll_button';

import { Application } from '@hotwired/stimulus';
import { controllerDefinitions } from './controllers/index';

window.Stimulus = Application.start();
window.Stimulus.load(controllerDefinitions);
