import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  get client() {
    if (typeof window._paq !== 'object') {
      throw new Error('The tracking code is not initialized');
    }

    return window._paq;
  }

  disable() {
    this.client.push(['forgetUserOptOut']);
  }

  enable() {
    this.client.push(['optUserOut']);
  }

  // Callbacks

  inputTargetConnected(element) {
    /* eslint-disable no-param-reassign */
    element.disabled = true;

    this.client.push([
      function retrieveStatus() {
        element.checked = this.isUserOptedOut();
        element.disabled = false;
      },
    ]);
    /* eslint-enable no-param-reassign */

    element.addEventListener('change', () => {
      if (element.checked) {
        this.enable();
      } else {
        this.disable();
      }
    });
  }
}
